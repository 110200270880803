<template>
  <div>
    <h1>Contacto</h1>
    <b-card-group deck>
      <b-card
        border-variant="primary"
        header-border-variant="primary"
        header="Dante Santoni R."
      >
        <b-card-text>
          
            <p><font-awesome-icon icon="phone-square" size="lg"
          /> +569.7587.8338</p>
            <p><font-awesome-icon icon="envelope-square" size="lg"
          /> dante.santoni@corsica.cl</p>
    
        </b-card-text>
      </b-card>

      <b-card
        border-variant="primary"
        header-border-variant="primary"
        header="Felipe Bastías J."
      >
        <b-card-text>
                      <p><font-awesome-icon icon="phone-square" size="lg"
          /> +569.9278.7226</p>
            <p><font-awesome-icon icon="envelope-square" size="lg"
          /> felipe.bastias@corsica.cl</p>
        </b-card-text>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginForm from '@/components/LoginForm.vue'

export default {
  name: "x",
  components: {},
};
</script>
