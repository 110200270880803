<template>
  <div>
    <h1>Plan para Pymes</h1>

    <p>
      Apoyamos pequeñas y medianas empresas en su transformación digital entendiendo que
      cada empresario tiene distintos objetivos y requerimientos diferentes y específicos.
    </p>
    <p class="font-italic font-weight-bold">
      Abordamos sus desafíos de negocio con un conjunto de soluciones de negocio de última
      generación que permiten un crecimiento digital orgánico, en tiempo reducido y bajo
      costo.
    </p>
    <p>Algunos problemas típicos que nos encontramos en nuestros clientes son:</p>

    <ul>
      <li>
        Mi sistema de facturación está obsoleto y no está en línea
        <b-button v-b-toggle.collapso1 variant="link">Más...</b-button>
      </li>

      <b-collapse id="collapso1">
        <b-card-sub-title>Zoho Books o Yogi.cl</b-card-sub-title>
        <b-card-text>
          ¿Está toda su facturación en un equipo bajo su escritorio? ¿No puede acceder
          desde casa? ¿No puede extender funcionalidad? De acuerdo a sus necesidades
          ofrecemos <b>Zoho Books</b> (solución de contabilidad de clase mundial)
          integrada al SII, o bien <b>Yogi.cl</b>, solución de facturación, inventario y
          pagos.
        </b-card-text>
      </b-collapse>

      <li>
        Tengo un equipo de ventas, y manejamos nuestras oportunidades en Excel
        <b-button v-b-toggle.collapso2 variant="link">Más...</b-button>
      </li>
      <b-collapse id="collapso2">
        <b-card-sub-title>Zoho CRM</b-card-sub-title>
        <b-card-text>
          Visibilidad y control completo sobre el proceso de ventas. Gestione clientes,
          contactos y oportunidades en la nube en el CRM más utilizado del mundo.
          Rentabilize su equipo de ventas poniendo foco en sus mejores oportunidades y
          apóyelos desde los datos.
        </b-card-text>
      </b-collapse>
      <li>
        Utilizo FLEXLINE y necesito que mis vendedores realicen cotizaciones desde terreno
        <b-button v-b-toggle.collapso3 variant="link">Más...</b-button>
      </li>
      <b-collapse id="collapso3">
        <b-card-sub-title>Integración</b-card-sub-title>
        <b-card-text>
          Desarrollamos aplicaciones cloud capaces de conectarse a su ERP on premise.
        </b-card-text>
      </b-collapse>
      <li>
        Requiero un sistema de tickets para la atención postventa
        <b-button v-b-toggle.collapso4 variant="link">Más...</b-button>
      </li>
      <b-collapse id="collapso4">
        <b-card-sub-title>Zoho DESK</b-card-sub-title>
        <b-card-text>
          Genere tickets desde correo, Twitter, o de su portal de clientes y mida tiempo
          de primera respuesta, de resolución y satisfacción. Clasifique y genere relación
          con sus clientes.
        </b-card-text>
      </b-collapse>
      <li>
        Necesito un portal de ventas online
        <b-button v-b-toggle.collapso5 variant="link">Más...</b-button>
      </li>
      <b-collapse id="collapso5">
        <b-card-sub-title>Shopify o Jumpseller</b-card-sub-title>
        <b-card-text>
          Administre su sitio de ventas en línea. Nos encargamos de integrar con el SII,
          redes sociales e inventario.
        </b-card-text>
      </b-collapse>
      <li>
        Necesito administrar inventario
        <b-button v-b-toggle.collapso6 variant="link">Más...</b-button>
      </li>
      <b-collapse id="collapso6">
        <b-card-sub-title>Zoho Inventory o Yogi</b-card-sub-title>
        <b-card-text>
          De acuerdo a la complejidad de su inventario, administre sus movimientos,
          multibodega, órdenes de compra y órdenes de venta.
        </b-card-text>
      </b-collapse>
      <li>
        No tengo visibilidad ni reportería de mi negocio
        <b-button v-b-toggle.collapso7 variant="link">Más...</b-button>
      </li>
      <b-collapse id="collapso7">
        <b-card-sub-title>Zoho Analytics</b-card-sub-title>
        <b-card-text>
          Sea cual sea su ERP o bien desde EXCEL, genere automáticamente los reportes,
          paneles de control y gráficos que le permitan tomar decisiones informadas.
        </b-card-text>
      </b-collapse>
    </ul>
    <b></b>
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginForm from '@/components/LoginForm.vue'

export default {
  name: "x",
  components: {},
};
</script>
