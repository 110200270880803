<template>
  <div>
    <carrusel />
    <hr>
    <p>Entendemos el cambio tecnológico como una necesidad que debe ser abordada como parte
    integral la estrategia general de una compañía. 
     Estas directrices permiten definir una
    hoja de ruta digital priorizando actividades, limitando costos y midiendo resultados.
    </p>
    <p> Son los procesos y no la tecnología los que generan beneficios, por lo que apoyamos a las organizaciones a trabajar con nuevas prácticas, actividades e interrelaciones.

    </p>
        <p> Estamos constantemente evaluando nuevas tecnologías, paquetes comerciales y arquitecturas que nos permiten abordar problemas de negocio de forma eficiente, expandible e integrable. 
          Si bien contamos con experiencia en diversas tecnologías que incluyen inteligencia artificial e IOT, nuestro core son:
          <ul>
            <li>Desarrollo e integración con soluciones Zoho
            </li>
            <li>Desarrollo de aplicaciones web con Frontend modo "Single Page Application" y Backend vía Api REST.
            </li>
          </ul>
    </p>
  </div>
</template>

<script>
// @ is an alias to /src
import Carrusel from "@/components/Carrusel.vue";

export default {
  name: "Home",
  components: {
    Carrusel,
  },
};
</script>
