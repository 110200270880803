<template>
  <div>
    <h1>Servicios</h1>

    <b-card
      img-src="./images/x_estrategia.jpg"
      img-left
      border-variant="primary"
      header-border-variant="primary"
      title="Estrategia Digital"
      align="left"
    >
      <b-card-text
        >Análisis estratégico de la organización y su interacción con clientes,
        proveedores, socios y gobierno bajo diferentes marcos de análisis junto con el
        estudio de tendencias y mejores prácticas de la industria. Entregamos un
        diagnóstico y hoja de ruta de un portafolio de iniciativas digitales.</b-card-text
      >
    </b-card>
    <br />
    <b-card
      img-src="./images/x_transformacion.jpg"
      img-right
      border-variant="primary"
      title="Transformación Digital"
      header-border-variant="primary"
      align="left"
    >
      <b-card-text
        >Gestión y soporte a las diferentes iniciativas digitales, considerando
        levantamiento de tecnologías, proveedores, definición de arquitectura e
        integración de tecnologías considerando tiempos (time to market), costos,
        modularización, escalabilidad e integrabilidad.
      </b-card-text> </b-card
    ><br />
    <b-card
      img-src="./images/x_apoyo.jpg"
      img-left
      border-variant="primary"
      title="Apoyo Experto"
      header-border-variant="primary"
      align="left"
    >
      <b-card-text
        >Entendemos el escenario digital como un ecosistema de soluciones integrables
        donde es posible obtener beneficios en plazos muy breves sin reinventar la rueda.
        Especialistas en:
        <b>
          <ul>
            <li>Aplicaciones web</li>
            <li>Integraciones</li>
            <li>ZOHO Platform</li>
          </ul></b
        >
      </b-card-text> </b-card
    ><br />
    <b-card
      img-src="./images/x_desarrollo.jpg"
      img-right
      border-variant="primary"
      title="Desarrollo de Aplicaciones"
      header-border-variant="primary"
      align="left"
    >
      <b-card-text>
        Desarrollamos aplicaciones web y móviles en tecnología de punta (Single Page
        Aplication + API REST).
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginForm from '@/components/LoginForm.vue'

export default {
  name: "x",
  components: {},
};
</script>
