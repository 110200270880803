//importaciones
import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false



/////////////////////////////////////////////////
//BOOTSTRAP
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//import './assets/styles/sketchy/bootstrap.min.css';
import './assets/styles/united/bootstrap.min.css';
//import './assets/styles/superhero/bootstrap.min.css';

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


//FONT AWESOME
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faUser, faSpinner, faPhoneSquare, faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons'
library.add(faUser, faSpinner, faPhoneSquare, faEnvelopeSquare)
Vue.component('font-awesome-icon', FontAwesomeIcon)

//Google Analytics

//import VueGtag from "vue-gtag";

//Vue.use(VueGtag, {
//  config: { id: "G-XBXJ1J72RJ" }
//});



new Vue({
  router,
    render: h => h(App)
}).$mount('#app')