<template>
  <div>

        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide
            v-for="(imagen, index) in imagenes"
            :key="index"
            :caption="imagen.titulo"
            :text="imagen.texto"
            :img-src="imagen.url"
          ></b-carousel-slide>
        </b-carousel>

  </div>
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
      sliding: null,
      imagenes: [
        {
          url: "./images/c_estrategia.jpg",
          titulo: "ESTRATEGIA DIGITAL",
          texto: "Codiseñamos su estrategia digital y programamos una hoja de ruta.",
        },
        {
          url: "./images/c_personas.jpg",
          titulo: "GESTIÓN DEL CAMBIO",
          texto: "Acompañamos a su equipo en la adopción de nuevas prácticas y tecnologías.",
        },
        {
          url: "./images/c_tecnologia.jpg",
          titulo: "TECNOLOGÍA",
          texto: "Contamos con competencias en las tecnologías más avanzadas del mercado.",
        },

      ],
    };
  },
  methods: {
    onSlideStart(slide) {
      console.log(slide);
      this.sliding = true;
    },
    onSlideEnd(slide) {
      console.log(slide);
      this.sliding = false;
    },
  },
};
</script>
