import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import QueHacemos from '../views/QueHacemos.vue'
import Servicios from '../views/Servicios.vue'
import Soluciones from '../views/Soluciones.vue'
import Contacto from '../views/Contacto.vue'
import Login from '../views/Login.vue'
import PlanPymes from '../views/PlanPymes.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/queHacemos',
    component: QueHacemos,
  },
  {
    path: '/servicios',
    component: Servicios,
  }
  ,
  {
    path: '/soluciones',
    component: Soluciones,
  },
  {
    path: '/contacto',
    component: Contacto,
  }
  ,
  {
    path: '/login',
    component: Login,
  }  ,
  {
    path: '/planPymes',
    component: PlanPymes,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
