<template>
  <div>
    <h1>Soluciones</h1>
    <b-card-group columns>
      <b-card
        title="Facturación y Pagos"
        img-src="./images/s_facturacion.jpg"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem"
        class="mb-2"
      >
        <b-card-text>
          <p>
            Integre su operación a factura electrónica. Contamos con ERPs (Órdenes de
            Venta, Facturación y Pagos) personalizados junto con proveer integración al
            SII desde cualquier plataforma.
          </p>
          <p class="font-italic">
            ¿Quiere contabilidad de clase mundial? Integramos con Zoho Books.
          </p>
        </b-card-text>
      </b-card>
      <b-card
        title="Gestión de Producción"
        img-src="./images/s_produccion.jpg"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem"
        class="mb-2"
      >
        <b-card-text>
          Notifique a bodega o producción cada vez que realice una venta. Tenga completa
          visibilidad de su linea de producción para entrega y facturación.
        </b-card-text>
      </b-card>
      <b-card
        title="Mesa de Ayuda"
        img-src="./images/s_mesa.jpg"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem"
        class="mb-2"
      >
        <b-card-text>
          Mejore la atención de sus clientes a través de tickets de ayuda por correo,
          teléfono o redes sociales. Mida el tiempo de respuesta, de resolución y la
          satisfacción de sus clientes.
        </b-card-text>
      </b-card>

      <b-card
        title="Gestión de Ventas"
        img-src="./images/s_ventas.jpg"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem"
        class="mb-2"
      >
        <b-card-text>
          Si aún se junta los viernes a revisar el excel de oportunidades es tiempo de
          cambiar al ERP de mayor uso en el mundo. Personalizamos y apoyamos a tu equipo a
          vender más y 'planillar' menos.
        </b-card-text>
      </b-card>
      <b-card
        title="Gestión de Activos + IOT"
        img-src="./images/s_activos.jpg"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem"
        class="mb-2"
      >
        <b-card-text>
          Digitalice su estrategia de mantenimiento e integre los datos de sus equipos.
          Tenga un registro completo de sus mantenimientos y anticipe mantenimientos
          programados.
        </b-card-text>
      </b-card>
      <b-card
        title="Gestión Documental"
        img-src="./images/s_alexandria.jpg"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem"
        class="mb-2"
      >
        <b-card-text>
          Sistema orientado a proyectos de ingeniería para administrar múltiples formatos, revisiones, versiones de planos, contratos, garantías, facturas, y especificaciones con tal de disponer de la última versión para todos los  usuarios y roles.
        </b-card-text>
      </b-card>
      <b-card
        title="Gestión de Activos Digitales"
        img-src="./images/s_proactivanet.jpg"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem"
        class="mb-2"
      >
        <b-card-text>
          ProactivaNET es una solución de clase mundial para el descubrimiento y gestión de activos digitales. Sepa que hardware y software tiene en su organización, administre licencias e integre su mesa de ayuda.
        </b-card-text>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginForm from '@/components/LoginForm.vue'

export default {
  name: "x",
  components: {},
};
</script>
