<template>
  <div id="app">
    <NavBar />
    <br>
    <b-container>
      <router-view />
    </b-container>
  </div>
</template>
<script>
//la barra y el router link, nada mas
//import HelloWorld from './components/HelloWorld';
import NavBar from "@/components/NavBar.vue";

export default {
  name: "App",

  components: {
    NavBar,
  },

  data: () => ({
    //
    //apiurl : 'http://localhost:8081/mtobridge/api/'
  }),
};
</script>
