<template>
    <div>
      <h1>Que Hacemos</h1>
    </div>
</template>

<script>
// @ is an alias to /src
//import LoginForm from '@/components/LoginForm.vue'


export default {
  name: 'x',
  components: {
    
  }
}
</script>