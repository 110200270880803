<template>
    <LoginForm />
</template>

<script>
// @ is an alias to /src
import LoginForm from '@/components/LoginForm.vue'

 
export default {
  name: 'x',
  components: {
    LoginForm
  }
}
</script>