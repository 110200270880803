<template>
  <b-card
    class="shadow mx-auto"
    header="Acceso clientes"
    style="max-width: 25rem"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <b-form-group label="Nombre de usuario">
      <b-input
        type="text"
        v-model="username"
        required
        autofocus
        autocomplete="off"
        :formatter="formatterUsername"
      />
      <small class="form-text text-mute">Su cuenta de usuario</small>
    </b-form-group>
    <b-form-group label="Contraseña">
      <b-input type="password" v-model="password" required />
      <small class="form-text text-danger">{{ mensaje }}</small>
      <small class="form-text text-mute"
        ><router-link to="/recuperarClave">¿No recuerdo mi clave?</router-link></small
      >
    </b-form-group>
    <b-button class="btn-block" variant="primary" @click="login">Entrar</b-button>
  </b-card>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginForm",
  data() {
    return {
      username: "",
      password: "",
      mensaje: "",
      apiurl: process.env.VUE_APP_API,
    };
  },
  methods: {
    formatterUsername(value) {
      return value.toLowerCase();
    },
    login: function () {
      var self = this;
      axios
        .post(this.apiurl + "usuario.php", {
          request: "login",
          username: this.username,
          password: this.password,
        })
        .then(function (response) {
          console.log(response.data);
          if (response.data) {
            var codigo = response.data.codigo;
            self.mensaje = response.data.mensaje;
            if (codigo == 0) {
              sessionStorage.setItem("menu", JSON.stringify(response.data.menu));
              sessionStorage.setItem(
                "menusiiworld",
                JSON.stringify(response.data.menusiiworld)
              );
              sessionStorage.setItem("usuario", JSON.stringify(response.data.usuario));
              sessionStorage.setItem("acciones", JSON.stringify(response.data.acciones));
              sessionStorage.setItem("config", JSON.stringify(response.data.config));
              self.$root.$emit("actualizaMenu");
              //console.log(self.$route.path);
              if ("/" != self.$route.path) self.$router.push({ name: "Inicio" });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          self.mensaje = "el email y la contraseña no corresponden";
        });
    },
  },
};
</script>
