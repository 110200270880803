<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="primary">

      
<router-link to="/" tag="b-navbar-brand">CORSICA</router-link>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
<!-- Left aligned nav items -->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
           <router-link to="/" tag="b-nav-item">Inicio</router-link>
          <router-link to="/soluciones" tag="b-nav-item">Soluciones</router-link>
          <router-link to="/servicios" tag="b-nav-item">Servicios</router-link>          
          <router-link to="/planPymes" tag="b-nav-item">Plan Pymes</router-link>          
          <!--b-nav-item-dropdown text="Soluciones" right>
            <b-dropdown-item href="#">Gestión de Clientes</b-dropdown-item>
            <b-dropdown-item href="#">Gestión de Operaciones</b-dropdown-item>
            <b-dropdown-item href="#">Procesos de Negocio</b-dropdown-item>
          </b-nav-item-dropdown-->
            <router-link to="/contacto" tag="b-nav-item">Contacto</router-link>          
                 
          <router-link tag="b-nav-item" to="/login" title="Acceso clientes">
            <font-awesome-icon icon="user"
          /></router-link>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
